@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

.comicImage {
    max-height: 500px;
    max-width: 1200px;
}
.comicAnimation {
    max-width: 600px;
    height: auto;
}
.comicAnimation img {
    max-width: 600px;
    height: auto;
}
.comicAnimation .simZero {
    width: 600px;
    height: 370px;
}
.comicAnimation .simOne {
    width: 600px;
    height: 380px;
}
.comicAnimation .simTwo {
    width: 600px;
    height: 505px;
}
.comicAnimation .simThree {
    width: 600px;
    height: 555px;
}

@media screen and (max-width: 700px) {
    .comicImage {
        max-height: 300px;
        max-width: 650px;
    }
}

@media screen and (max-width: 650px) {
    .comicAnimation {
        max-width: 480px;
        height: auto;
    }
    .comicAnimation img {
        max-width: 480px;
        height: auto;
    }
    .comicAnimation .simZero {
        width: 480px;
        height: 296px;
    }
    .comicAnimation .simOne {
        width: 480px;
        height: 304px;
    }
    .comicAnimation .simTwo {
        width: 480px;
        height: 404px;
    }
    .comicAnimation .simThree {
        width: 480px;
        height: 444px;
    }
    .comicAnimation iframe {
        -ms-zoom: 0.80;
        -moz-transform: scale(0.80);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.80);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.80);
        -webkit-transform-origin: 0 0;
        transform: scale(0.80);
        transform-origin: 0 0;
    }
}

@media screen and (max-width: 500px) {
    .comicImage {
        max-height: 150px;
        max-width: 450px;
    }
    .comicAnimation {
        max-width: 300px;
        height: auto;
    }
    .comicAnimation img {
        max-width: 300px;
        height: auto;
    }
    .comicAnimation .simZero {
        width: 300px;
        height: 190px;
    }
    .comicAnimation .simOne {
        width: 300px;
        height: 200px;
    }
    .comicAnimation .simTwo {
        width: 300px;
        height: 253px;
    }
    .comicAnimation .simThree {
        width: 300px;
        height: 278px;
    }
    .comicAnimation iframe {
        -ms-zoom: 0.50;
        -moz-transform: scale(0.50);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.50);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.50);
        -webkit-transform-origin: 0 0;
        transform: scale(0.50);
        transform-origin: 0 0;
    }
}

@media screen and (max-width: 275px) {
    .comicImage {
        max-height: 75px;
        max-width: 250px;
    }
    .comicAnimation {
        max-width: 100px;
        height: auto;
    }
    .comicAnimation img {
        max-width: 100px;
        height: auto;
    }
    .comicAnimation .simZero {
        width: 100px;
        height: 62px;
    }
    .comicAnimation .simOne {
        width: 100px;
        height: 64px;
    }
    .comicAnimation .simTwo {
        width: 100px;
        height: 85px;
    }
    .comicAnimation .simThree {
        width: 100px;
        height: 93px;
    }
    .comicAnimation iframe {
        -ms-zoom: 0.17;
        -moz-transform: scale(0.17);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.17);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.17);
        -webkit-transform-origin: 0 0;
        transform: scale(0.17);
        transform-origin: 0 0;
    }
}

.MuiCardContent-root:last-child {
    padding-bottom: 16px;
}

.sources {
    font-family: 'Patrick Hand', cursive;
}
