.grid {
    display: flex;
    height: 210px;
    width: 450px;
}

.gridButton {
    height: 20px;
    width: 20px;
}

@media screen and (max-width: 470px) {
    .grid {
        width: 338px;
        height: 158px;
    }
    .gridButton {
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width: 380px) {
    .grid {
        width: 225px;
        height: 105px;
    }
    .gridButton {
        width: 10px;
        height: 10px;
    }
}