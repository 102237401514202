.loader {
    border: 16px solid #f3f3f3; /* Track color */
    border-top: 16px solid #282c34; /* Spinner color */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.clickable:hover {
    cursor: pointer;
}

.generated-question {
    border: 2px solid #000000;
    background-color: #ffffff;
    padding: 25px;
    position: relative;
    margin-top: 1em;
    margin-right: 2em;
}

.generated-question p {
    margin-top: 0;
}

.generated-question .controls {
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: bold;
}

/* Question selection annotation section */
/* The container must be positioned relative: */

.custom-select select {
    background-color: #eeeeee;
    border: 0;
    font-size: 20px;
    color: #666666;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 700;
    border-bottom: 3px solid #666666;
    appearance: none;
}

.custom-select select:active, select:focus, select:focus-visible, select:visited {
    outline: 0;
    border-bottom: 3px solid #666666;
}

/*These are supposed to change the select background but they dont really work as
as intended because the background is rendered by the OS
*/
/*.custom-select select.decorated option:hover, option:checked {*/
/*    box-shadow: 0 0 10px 100px #ff563f inset;*/
/*}*/

/*select:focus > option:checked {*/
/*    background: #000 !important;*/
/*}*/

.custom-select select option {
    padding-left: 20px;
    font-size: 20px;
    color: #666666;
}

a.button {
    text-decoration: none;
}

.button {
    width: auto;
    background-color: #000;
    color: #fff;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    margin-right: 2em;
    margin-top: 1em;
    margin-bottom: 1em !important;
    text-align: center;
    font-weight: bold;
    border: none !important;
}

.button:hover {
    background-color: #ff563f;
    transition: 500ms;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    cursor: pointer;
}
