.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.hover-paper {
    /* !important is needed to override the default background-color of mui Paper */
    background-color: #f5f5f5 !important;
    padding: 10px;
    margin-bottom: 10px;
}

.hover-paper:hover {
    /* !important is needed to override the background-color of .hover-paper */
    background-color: rgba(255, 86, 63, 0.10) !important;
}

.hover-paper.active {
    /* !important is needed to override the background-color of .hover-paper */
    background-color: rgba(255, 86, 63, 0.25) !important;
}

/*#feedbackPanel {*/
/*  position: absolute;*/
/*  top: 100%;*/
/*  left: -50%;*/
/*  width: 200%;*/
/*}*/

#feedbackPanel Button {
    margin-right: 0.5em;
}

#card-navigator-toggle {
    padding: 1em;
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    width: 10px;
    position: absolute;
    left: 15px;
    font-weight: 900;
}

@media only screen and (min-width: 1101px) {
    #card-navigator-toggle {
        display: none;
    }
}

@media only screen and (max-width: 1100px) {
    .card-navigator.show {
        display: block;
    }

    .card-navigator.hide {
        display: none;
    }

    #card-navigator-toggle {
        display: block;
    }

    #card-navigator-toggle {
        top: 100px;
    }
}

@media only screen and (max-width: 600px) {
    #card-navigator-toggle {
        top: -80px;
    }
}

.homepage-inline-login {
    font-size: 20px !important;
    border-radius: 50px !important;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    margin-top: 10px !important;
    margin-left: 15px !important;
}

/* General styles for all pages */
a {
    color: #FF563F;
}

a.button {
    text-decoration: none;
}

.button {
    width: auto;
    background-color: #000;
    color: #fff;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    margin-right: 2em;
    text-align: center;
    font-weight: bold;
    border: none !important;
}

.button:hover {
    background-color: #ff563f;
    transition: 500ms;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    cursor: pointer;
}

#assignments .button {
    float: left;
}

/* Two columns each occupying 50% of parent */
.dual-col-left {
    float: left;
    width: 48%;
    margin-right: 4%;
}

.dual-col-right {
    float: left;
    width: 48%;
}

/* Columns of the specified percent width of page (i.e. col-25 is 25 percent of page width) */
.col-25 {
    float: left;
    width: 25%;
}

.col-50 {
    float: left;
    width: 50%;
}

.col-33 {
    float: left;
    width: 33%;
}

.col-66 {
    float: left;
    width: 66%;
}

.col-75 {
    float: left;
    width: 75%;
}

.internal-padding {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
}

/* Columns with left class have a right margin since other elements are to their right */
.left {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding-right: 3%;
}

/* Review card styles*/
.review-card {
    box-shadow: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.card-content, p.card-content {
    font-size: 1.5em;
    line-height: 1.4em;
}

.katex {
    font-size: 1.5em;
}

.review-card input, .review-card textarea {
    font-size: 1.5em !important;
    margin-top: 20px !important;
    max-width: 100%;
}

.review-card .button {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 14em;
    font-size: 1.3em;
}


.manual-grading {
    font-size: 20px;
}

.manual-grading Button {
    margin-right: 10px;
    font-size: 20px;
}

.last {
    margin-right: 0 !important;
}

/* Navbar styles */
#navbar-desktop {
    position: fixed;
    z-index: 100;
    background-color: #ffffff;
    top: 0;
    width: 100%;
    height: 100px;
}

.navbar-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.navbar-image {
    float: left;
}

.navbar-buttons {
    float: right;
    position: relative;
    top: 50px;
    transform: translateY(-50%);
}

.navbar-button {
    font-size: 1.3em !important;
    padding: 1em;
    box-shadow: inset 0 0 0 0 #FF563F;
    transition: color 0.7s ease-in, box-shadow 0.5s ease-in !important;
    border: 2px solid #000000 !important;
    margin-left: 10px !important;
}

.navbar-button:hover {
    box-shadow: inset 200px 0 0 0 #FF563F;
}

#navbar-mobile {
    display: none;
}

@media only screen and (max-width: 900px) {
    #navbar-desktop {
        display: none;
    }

    #navbar-mobile {
        display: block;
    }
}

/* Formatting of content */
.container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
    margin-bottom: 5em;
}

/* Course info */
#course-writeup {
    width: 66%;
    float: left;
}

#course-writeup h1 {
    font-size: 3em;
}

#course-writeup p {
    padding-right: 2em;
}

#course-status {
    width: 33%;
    float: left;
    border: solid 5px #000;
    padding-top: 0.25em;
    padding-bottom: 5.5em;
}

#course-status .button {
    width: 215px;
    position: relative;
    left: 50%;
    top: 20px;
    margin-left: -107px;
    margin-right: 0;
    margin-bottom: 1.5em;
}

.course-fact {
    margin-left: 10%;
    width: 40%;
    float: left;
}

.course-fact h3 {
    font-size: 1.5em;
    margin-bottom: 0;
}

.course-fact p {
    font-size: 1em;
    margin-top: 0;
}

@media only screen and (max-width: 1290px) {
    #course-writeup {
        width: 100%;
    }

    #course-status {
        width: 100%;
    }
}

/* Assignments */
h2.thumbnail-panel, h2.course-title {
    font-size: 2em;
}

.thumbnail-img {
    float: left;
    border: solid 3px #000;
    margin-right: 2.5em;
    width: 15%;
    box-shadow: 10px 10px #ff563f;
}

.thumbnail-txt {
    float: left;
    max-width: 100%;
}

.thumbnail-txt h4 {
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 0;
}

.thumbnail-txt p {
    color: #ff563f;
    margin-top: 0;
}

@media only screen and (max-width: 1290px) {
    .thumbnail-txt .button {
        margin-bottom: 10px;
    }

    .thumbnail-img {
        width: 30%;
        margin-bottom: 30px;
    }
}

/* Instructor page */
#top-panels {
    margin-top: 3em;
    margin-bottom: 3em;
}

#top-panels .thumbnail-img {
    min-width: 32%;
}

/* Content review page */
#textbook-content {
    font-weight: 300;
}

#textbook-content p {
    font-size: 25px;
    font-weight: 300;
    margin-top: 0;
}

.highlight {
    border: 1px solid #d4d4d4;
    padding: 2em;
    background-color: #fbfbfb;
}

.highlight p {
    margin: 0;
}

.answer-option {
    float: left;
    padding: 1em;
    width: 16em;
    display: flex;
    align-items: center;
}

.answer-option i {
    margin-right: 1em;
}

.correct {
    /*border: 1px solid #dbdbdb*/
    box-shadow: 0px 0px 5px 5px lightgrey;
}

.circle {
    height: 3em;
    width: 3.75em;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
    margin-right: 1em;
}

.incorrect .circle {
    background-color: #e30713;
}

.correct .circle {
    background-color: #09aa6e;
}

.answer-reveal {
    display: flex;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 0.5em;
}

.answer-reveal p {
    margin-bottom: 0;
    margin-top: 0;
}

.completion-indicator {
    width: 320px;
    text-align: center;
    position: relative;
    left: 20px;
    z-index: -9;
    box-shadow: none !important;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.question-progress-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
}

.question-progress-panel p {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
}

.status-box {
    background-color: #f0f0f0;
    width: 25px;
    height: 15px;
    float: left;
    margin-right: 2px;
}

.status-box.red-bg {
    background-color: #ff563f;
}

.status-box.black-bg {
    background-color: #000000;
}

#spaced-rep-txt {
    position: absolute;
    top: 30%;
    right: 8%
}

.question-progress-panel p {
    font-size: 1em;
}

#textbook-content .col-25.right {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding-left: 2%;
}

.interval-table-cell {
    background-color: #f0f0f0;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.interval-table-cell.left {
    padding-left: 5px;
}

/* Dag view page */
#dag-content {
    font-weight: 300;
}

#dag-content h4 {
    font-size: 2em;
    margin-top: 0;
    font-weight: 500;
}

#dag-content h6 {
    font-size: 1.3em;
    font-weight: 500;
}

#dag-content .col-25.right {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding-left: 2%;
}

#dag-content .col-25.right .button {
    width: 10em;
}

.added-concept {
    text-align: center;
}


/* Question generation page */
#question-generation-content .container {
    display: flex;
}

#question-generation-content .col-66 {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding-left: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.textbook-upload-button {
    border: dashed 10px #000;
    font-size: 3em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 20px 20px #ff563f;
}

.textbook-upload-button p {
    font-weight: bold;
    padding: 15px;
}

#question-generation-content .col-33 {
    position: relative;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding-right: 2%;
}

/*#question-generation-content .shadow {*/
/*    width: -webkit-fill-available;*/
/*    height: -webkit-fill-available;*/
/*    position: absolute;*/
/*    top: 20px;*/
/*    left: 20px;*/
/*    border-right: 20px solid #ff563f;*/
/*    border-bottom: 20px solid #ff563f;*/
/*}*/

.generated-question {
    border: 2px solid #000000;
    background-color: #ffffff;
    padding: 10px;
    position: relative;
    margin-top: 1em;
    margin-right: 2em;
}

.generated-question p {
    margin-top: 0;
}

.generated-question .controls, .quiz-question .controls {
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: bold;
}

/* Question selection */
#question-selection-content .col-75 {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding-left: 4%;
}

#question-selection-content .col-25 {
    text-align: center;
}

.question-selector {
    float: left;
    width: 50%;
}

.checkbox {
    width: 20px;
    height: 20px;
    float: left;
}

.checkbox {
    float: left;
    background-color: #ffffff;
    outline: 4px solid #000000;
    border: 0;
    outline-offset: 3px;
    margin-top: 6px;
}

.checkbox.checked {
    background-color: #ff563f;
}

.question-content {
    border: 4px solid #000000;
    float: left;
    width: 80%;
    margin-left: 5%;
    min-height: 30em;
    position: relative;
}

.recommended {
    width: fit-content;
    background-color: #000;
    color: #fff;
    padding: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
    margin-right: 2em;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}


/* Student management */
#student-management-content h1 {
    font-size: 2em;
}

.student-fact {
    float: left;
    width: 33%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    padding-left: 3%;
    padding-right: 3%;
}

.student-fact img {
    margin-bottom: 2em;
    min-height: 20em;
}

.caption {
    background-color: #ffddd9;
    color: #000000;
    width: 100%;
    padding: 1em;
    font-size: 1.5em;
    font-weight: bold;
}

/* Textbook section */
#textbook-content h4 {
    font-size: 1.5em;
    margin-top: 0;
    margin-bottom: 0;
}

/* Select cards section */
.card-selector-box {
    display: inline-block;
    margin-top: 40px;
    width: 100% !important;
}

.card-selector-box .feedback-button {
    display: none;
}

.card-selector-box .review-card {
    border: 5px solid #000000;
    padding: 20px;
    padding-top: 0;
}

.card-selector-box .checkbox {
}

/* Daily review page*/
.daily-review-content .container {
    width: 95%;
}

.questions-remaining {
    text-align: center;
    font-weight: 400;
}

@media only screen and (max-width: 1400px) {
    .daily-review-content .container {
        width: 80%;
    }

    .daily-review-content .col-25 {
        width: 100%;
        margin-bottom: 100px;
    }

    .daily-review-content .col-50 {
        width: 100%;
        margin-bottom: 100px;
    }

    .card-navigation {
        width: 350px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Begin instructor question generation dashboard styles*/

#course-writeup input {
    font-size: 30px;
    width: 95%;
}

/* Checkbox todos */
.todos ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.tick {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: 3px solid #333;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    float: left;
}

.tick::before {
    content: '✓';
    font-size: 15px;
    display: none;
}

.todo {
    clear: both;
    margin-top: 15px;
}

.done .tick::before {
    display: inline;
}

.done.todo {
    text-decoration: line-through;
}

.compound-button {
    border: 3px solid #000000;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    color: #000000;
    padding: 10px;
}

.internal-button {
    background-color: #000000;
    color: #FFFFFF;
    width: 27%;
    float: right;
    padding: 5px;
    /*position: relative;*/
    /*bottom: 5px;*/
    text-align: center;
}

.internal-button:hover {
    background-color: #ff563f;
    cursor: pointer;
}

/* End instructor question generation dashboard styles */

/* Begin assignment panel styles */
.some-panel {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    min-height: 300px;
    display: grid;
    grid-template-columns: 3% 97%;
}

.up-down-navigation {
    grid-column-start: 1;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    position: relative;
}

.quiz-question {
    grid-column-start: 2;
    position: relative;
}

.square {
    background-color: #000000;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
}

.bottom-bar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

/* Upload material styles */

.inputted-material {
    width: 400px;
    font-size: 20px;
    padding: 15px;
    color: #000000;
    border: 5px solid #000000;
    margin-bottom: 10px;
}

/* End upload material styles */
